@font-face {
    font-family: "proxima-nova";
    src: url("../Proxima-Nova-Reg.ttf");
}

.App {
    text-align: left;
    font-family: Helvetica, "proxima-nova", "Futura", "Jam Grotesque", sans-serif;
    font-weight: 100;
    color: black;
    line-height: calc(16px + 1.5vmin);
}

.Home {
    text-align: left;
    font-family: Helvetica, "proxima-nova", "Futura", "Jam Grotesque", sans-serif;
    font-weight: 100;
    color: black;
    line-height: calc(16px + 1.5vmin);
    padding: 2vmin;
    margin: 1vmin;
    width: 78vw;
}

.pubsAndSocials {
    padding-left: 4vmin;
}

/* .About {
    text-align: left;
    font-family: Helvetica, "proxima-nova", "Futura", "Jam Grotesque", sans-serif;
    font-weight: 100;
    color: black;
    line-height: calc(16px + 1.5vmin);
    padding: 2vmin;
    margin: 1vmin;
    width: 78vw;
} */

.Timeline {
    text-align: left;
    font-family: Helvetica, "proxima-nova", "Futura", "Jam Grotesque", sans-serif;
    font-weight: 100;
    color: black;
    line-height: calc(16px + 1.5vmin);
    padding: 2vmin;
    margin: 1vmin;
    width: 78vw;
    /* min-height: calc(74vw * 11 / 8.5 + 50px); */
}

.Services {
    text-align: left;
    font-family: Helvetica, "proxima-nova", "Futura", "Jam Grotesque", sans-serif;
    font-weight: 100;
    color: black;
    line-height: calc(16px + 1.5vmin);
    padding: 2vmin;
    margin: 1vmin;
}

.Resume {
    text-align: left;
    font-family: Helvetica, "proxima-nova", "Futura", "Jam Grotesque", sans-serif;
    font-weight: 100;
    color: black;
    line-height: calc(16px + 1.5vmin);
    padding: 2vmin;
    margin: 1vmin;
}

.pdf {
    width: 74vw;
    /* US Letter size calculation from width, displaying two pages, plus margins in the PDF viewer. */
    height: calc(74vw * 11 / 8.5 * 2 + 50px);
}

.Contact {
    text-align: left;
    font-family: Helvetica, "proxima-nova", "Futura", "Jam Grotesque", sans-serif;
    font-weight: 100;
    color: black;
    line-height: calc(16px + 1.5vmin);
    padding: 2vmin;
    margin: 1vmin;
    width: 78vw;
    min-height: 100vh;
}

.container {
    /* display: flex;
        flex-direction: column;
        align-items: center; */
    padding-left: 3vmin;
    padding-right: 3vmin;
    padding-bottom: 3vmin;
    margin-left: 1vmin;
    margin-right: 1vmin;
    margin-bottom: 1vmin;
}

.logo {
    font-family: Helvetica;
    color: black;
    font-size: calc(30px + 1.4vmin);
    line-height: calc(30px + 1.5vmin);
    font-weight: 700;
    text-decoration: none;
    margin-right: 200px;
}

.menuItem {
    text-decoration: none;
    margin-top: 30px;
    display: inline-block;
    position: relative;
    color: black;
}

.menuItem::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: black;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.menuItem:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.caret {
    height: calc(10px + 1.5vmin);
}

.topAlignedCaret {
    height: calc(10px + 1.5vmin);
    margin-top: 3px;
}

.afterCaret {
    padding-left: calc((10px + 1.5vmin) * 1);
}

.afterInvisibleCaret {
    padding-left: calc((10px + 1.5vmin) * 4);
}

.diagonalArrow {
    height: calc(1.2vmin);
    padding-left: 5px;
}

.headshot {
    align-self: flex-start;
    height: 55vh;
}

.postPhoto {
    width: 100%;
}

.postPhotoInRow {
    max-height: 50vh;
}

.flyer {
    max-height: 80vh;
}

.photoRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    justify-content: space-between;
}

.leftAlignedPhotoRow {
    display: flex;
    flex-direction: row;
    align-items: left;
    /* max-width: 1117px; */
    justify-content: left;
    flex-wrap: wrap;
    width: 74vw;
    padding-bottom: 500px;
}

.post {
    max-width: 60vw;
}

.socials {
    min-width: calc(2 * (20px + 1.4vmin));
    margin-bottom: 1vmin;
}

.postDivider {
    margin-top: 40px;
    margin-bottom: 40px;
    border-bottom: 1px dashed #bfbfbf;
}

.socialsDivider {
    margin-top: 40px;
    margin-bottom: 40px;
    border-bottom: 1px dashed #bfbfbf;
}

.postTitle {
    font-size: calc(12px + 1.6vmin);
}

.paragraph {
    padding-top: 10px;
    padding-bottom: 10px;
}

.photoCredit {
    font-size: 1.8vmin;
    align-self: flex-end;
}

.photoCreditLink {
    color: black;
    text-decoration: none;
    width: min-content;
    font-size: 1.8vmin;
    align-self: flex-end;
}

.photoCreditRow {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
}

.photoColumn {
    display: flex;
    flex-direction: column;
}

.aboutMe {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
    padding-left: 3vmin;
    padding-right: 3vmin;
    padding-bottom: 3vmin;
    margin-right: 1vmin;
    margin-bottom: 1vmin;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.socialsRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.rowEnd {
    padding-left: 30vmin;
}

.topAlignedRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 3px;
}

.menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: calc(2 * (20px + 1.4vmin));
    padding: 4vmin;
    padding-right: 0;
    margin-left: 1vmin;
    margin-bottom: 1vmin;
    /* margin-right: 5vmin; */
}

.menuOptions {
    display: flex;
    flex-direction: column;
}

button {
    border-style: none;
    border-width: 0px;
    background-color: transparent;
    font: 100 calc(8px + 1.4vmin) Helvetica;
    text-align: left;
    padding: 0;
    cursor: pointer;
}

.header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(8px + 1.4vmin);
    padding: 2vmin;
    margin: 1vmin;
}

.link {
    color: black;
}

.socialLink {
    color: black;
    text-decoration: none;
    width: min-content;
}

.linkWithArrow {
    padding-right: 6vmin;
}

.linkNoUnderline {
    color: rgb(0, 191, 255);
    text-decoration: none;
}

a.link:hover {
    background-color: rgb(245, 245, 245);
}

a.linkNoUnderline:hover {
    background-color: rgb(245, 245, 245);
}

a.logo:hover {
    background-color: none;
}

ul {
    list-style-type: none;
}

@media screen and (min-width: 1200px) {
    .menu {
        width: 133px;
        padding-right: 10vmin;
    }
}

/* Mobile */
@media screen and (max-width: 1200px) {
    .Home,
    .Timeline,
    .Contact {
        width: 90vw;
    }

    .aboutMe {
        flex-wrap: wrap;
    }

    .rowEnd {
        padding-left: 60vmin;
    }

    .postPhoto {
        width: 100%;
    }

    .postPhotoInRow {
        max-width: 100%;
    }

    .flyer {
        max-width: 100%;
    }

    .topAlignedRow {
        padding-bottom: 10px;
    }

    .bullet {
        padding-bottom: 14px;
    }

    .pdf {
        width: 90vw;
        /* US Letter size calculation from width, displaying two pages, plus margins in the PDF viewer. */
        height: calc(90vw * 11 / 8.5 * 2 + 50px);
    }

    .photoRow {
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 100%;
        justify-content: space-around;
    }

    .leftAlignedPhotoRow {
        flex-wrap: wrap;
        display: contents;
        flex-direction: row;
        align-items: left;
        max-width: 100%;
        justify-content: space-around;
    }

    .column {
        margin-left: 1vmin;
    }

    .post {
        max-width: 100%;
        padding: 6vmin;
        margin: 1vmin;
    }

    .socials {
        max-width: 100%;
        padding: 3vmin;
        margin: 1vmin;
    }

    .postDivider {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .socialsDivider {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 3vmin;
    }

    .header {
        font-size: calc(10px + 1.6vmin);
    }

    .App {
        font-weight: 300;
    }

    .Home {
        font-weight: 300;
        padding: 0;
        margin: 0;
        padding-top: 2vmin;
        padding-bottom: 2vmin;
        margin-top: 1vmin;
        margin-bottom: 1vmin;
        margin-left: 1vmin;
    }

    /* .About {
        font-weight: 300;
        padding: 0;
        margin: 0;
        padding-top: 2vmin;
        padding-bottom: 2vmin;
        margin-top: 1vmin;
        margin-bottom: 1vmin;
        margin-left: 1vmin;
    } */

    .Services {
        font-weight: 300;
        padding: 0;
        margin: 0;
        padding-top: 2vmin;
        padding-bottom: 2vmin;
        margin-top: 1vmin;
        margin-bottom: 1vmin;
    }

    .Timeline {
        font-weight: 300;
        padding: 0;
        margin: 0;
        padding-top: 2vmin;
        padding-bottom: 2vmin;
        margin-top: 1vmin;
        margin-bottom: 1vmin;
        margin-left: 1vmin;
    }

    .Resume {
        font-weight: 300;
        padding: 0;
        margin: 0;
        padding-top: 2vmin;
        padding-bottom: 2vmin;
        margin-top: 1vmin;
        margin-bottom: 1vmin;
    }

    .Contact {
        font-weight: 300;
        padding: 0;
        margin: 0;
        padding-top: 2vmin;
        padding-bottom: 2vmin;
        margin-top: 1vmin;
        margin-bottom: 1vmin;
        margin-left: 1vmin;
    }

    .afterCaret {
        padding-left: calc((20px + 1.5vmin) * 1);
    }

    .afterInvisibleCaret {
        padding-left: calc((10px + 2vmin) * 4);
    }

    .diagonalArrow {
        height: calc(1.8vmin);
    }

    .headshot {
        height: 60vh;
    }

    .photoCredit {
        font-size: 2vmin;
    }

    .photoCreditLink {
        font-size: 2vmin;
    }

    .menu {
        width: 100%;
        flex-direction: column;
        padding-top: 1vmin;
        padding-bottom: 1vmin;
        margin: 1vmin;
        margin-right: 0;
        flex-wrap: wrap;
    }

    .menuOptions {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .menuItem {
        margin-top: 0;
        margin-right: 40px;
    }

    button {
        font: 100 calc(12px + 1.4vmin) Helvetica;
    }

    .logo {
        margin-right: 0;
        font-size: calc(25px + 1.4vmin);
        line-height: calc(16px + 1.5vmin);
    }

    .aboutMe {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 1000px) {
    .rowEnd {
        padding-left: 45vmin;
    }
}

@media screen and (max-width: 885px) {
    .postPhotoInRow {
        max-height: 40vh;
    }

    .menu {
        padding-top: 2vmin;
        padding-bottom: 2vmin;
    }

    .afterCaret {
        padding-left: calc((20px + 1.5vmin) * 1);
    }

    .afterInvisibleCaret {
        padding-left: calc((19px + 2vmin) * 3);
    }

    .rowEnd {
        padding-left: 35vmin;
    }

    .bullet {
        padding-bottom: 12px;
    }
}

@media screen and (max-width: 700px) {
    .postPhotoInRow {
        max-height: 25vh;
    }

    .menu {
        padding-top: 4vmin;
        padding-bottom: 4vmin;
    }

    .afterCaret {
        padding-left: calc((22px + 1.5vmin) * 1);
    }

    .afterInvisibleCaret {
        padding-left: calc((19px + 2vmin) * 3);
    }

    .rowEnd {
        padding-left: 30vmin;
    }
}

@media screen and (max-width: 446px) {
    .postPhotoInRow {
        max-height: 20vh;
    }
}
